const hasAndroidStorage = !!window?.MultiNativeStorage?.setItem;
const hasIosStorage = !!window?.webkit?.messageHandlers?.multiNativeStorageSetItem?.postMessage;

(function multiStorage(window) {
  window.multiStorage = {
    getItem: (key) => localStorage.getItem(key),
    setItem: (key, value) => {
      localStorage.setItem(key, value);
      if (hasAndroidStorage) window.MultiNativeStorage.setItem(key, value);
      if (hasIosStorage) window.webkit.messageHandlers.multiNativeStorageSetItem.postMessage({ key, value });
    },
    removeItem: (key) => {
      localStorage.removeItem(key);
      if (hasAndroidStorage) window.MultiNativeStorage.removeItem(key);
      if (hasIosStorage) window.webkit.messageHandlers.multiNativeStorageRemoveItem.postMessage({ key });
    },
  };
  if (hasAndroidStorage) window.MultiNativeStorage.setAll(JSON.stringify(localStorage));
  if (hasIosStorage)
    window.webkit.messageHandlers.multiNativeStorageSetAll.postMessage(JSON.parse(JSON.stringify(localStorage)));
})(window);
